const Concept = () => {
  return (
    <div>
      <div className="m-0 px-5 pt-[100px] lg:px-0 md:pt-[115px] sm:py-10">
        <div className="min-w-0 max-w-[1312px] mx-auto flex flex-col">
          <div className="flex flex-row sm:flex-col">
            <div className="w-1/2 sm:w-full">
              <div className="m-0 p-0 polygonItem" data-aos="fade-zoom-in">
                <div className="ml-0 m-5">
                  <div className="relative text-left">
                    <div className=" inline-block max-w-full polygonItem-content sm:w-[258px] md:w-[258px]">
                      <img
                        src="./assets/img/stakingItem.png"
                        alt="stakingItem"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 sm:w-full sm:max-w-[400px] mx-auto">
              <div
                className="w-full m-0 mt-8 p-0 pl-[9%] sm:mt-0 sm:pl-0"
                data-aos="fade-left"
              >
                <div className="m-5">
                  <h2 className="text-white text-center font-Poppins font-medium m-0 p-0 leading-[1.4] tracking-[0] text-[39px] lg:text-[31.2px] md:text-[26px] sm:text-[30px]">
                    <span>Why Crypto Staking?</span>
                  </h2>
                </div>
                <div className="m-5 mt-0 sm:mt-[-7px] md:mt-[-7px]">
                  <div className="font-Poppins text-center text-white/70 font-normal leading-[26px] text-[16px] lg:text-[14px] md:text-[10px] md:leading-[20px] sm:text-[14px] sm:leading-[22px]">
                    <p>
                      Staking crytocurrencies is a process that involves
                      commiting your cryto assets to support a blockchain
                      network and confirm transactions. With crytocurrencies
                      that use the proof-of-stake model, new transactions need
                      to be validated before being added to the blockchain
                      Participants pledge their coins to the crytocurrency
                      protocol. From those participants, the protocol chooses
                      validators to confirm blocks of transactions. New
                      cryptocurrency coins are minted and rewarded to its
                      validator when a new block is added to the blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full m-0 p-0 flex flex-col">
            <div className="m-5 mt-[-10px] mr-[-17px] sm:mr-5 flex justify-center">
              <div
                className="max-w-full sm:w-[290px] md:w-[290px] stakingItem2 mx-auto"
                data-aos="fade-zoom-in"
              >
                <img src="./assets/img/stakingItem2.png" alt="stakingItem2" />
              </div>
            </div>
            <div className="w-full m-5 mt-0" data-aos="fade-up">
              <h2 className="text-white text-center font-Poppins font-medium m-0 p-0 leading-[1.4] tracking-[0] text-[39px] lg:text-[31.2px] md:text-[26px] sm:text-[30px]">
                <span>When you should stake crypto?</span>
              </h2>
            </div>
            <div className="w-full m-5 mt-0">
              <div
                data-aos="fade-up"
                className="mr-5 font-Poppins text-center text-white/70 font-normal leading-[26px] text-[16px] lg:text-[14px] md:text-[10px] md:leading-[20px] sm:text-[10px] sm:leading-[20px]"
              >
                <p className="max-w-[977px] my-0 mx-auto">
                  If you have crypto you can stake and you aren't planning to
                  trade it in the near future, then you should stake it. It
                  doesn't require any work on your part, and you'll be earning
                  more crypto. The proof-of-stake model has been beneficial for
                  both cryptocurrencies and crypto investors. Cryptocurrencies
                  can use proof of stake to process large numbers of
                  transactions at minimal costs. Crypto investors also get the
                  opportunity to collect passive income from their holdings. Now
                  that you know more about staking, you can start investigating
                  on Adrestus that offer it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concept;
