const AccessWhitePaper = () => {
  return (
    <div className="w-full pt-[321px] pb-[78px] sm:pt-[140px] sm:pb-[79px]lg:px-[100px] mx-auto bg-slate-300 WPBackground">
      <div className="WPBackground1 w-full m-auto flex flex-col items-center justify-center relative">
        <h2 className="sm:text-[30px] sm:tracking-[5px] md:text-[64px] tracking-[8px] text-[70px] p-0 m-0 z-10">
          <span className="letter-color font-semibold font-Overpass">
            THE ADRESTUS
          </span>
        </h2>
        <h2 className="sm:text-[22px] tracking-[0px] md:text-[33px] lg:text-[34px] text-[36px] p-0 m-0 z-10 sm:text-center">
          <span className=" text-white font-semibold font-Overpass">
            Dives Further Into The Technical Capabilities
          </span>
        </h2>
        <div className="flex items-center justify-center py-6 m-0 z-10">
          <button className="sm:w-[230px] sm:h-[50px] md:w-[230px] md:h-[50px] w-[291px] h-[66px] rounded-[33px] colorBtn">
            <a
              href="https://github.com/Adrestus-net/Adrestus"
              className="flex justify-center items-center"
            >
              <span className="text-[#081113] text-[16px] md:text-[12px] sm:text-[12px] left-5 font-semibold text-center font-Poppins">
                Access The White Paper
              </span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessWhitePaper;
