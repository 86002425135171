const tableData = [
  {
    name: "Transactional Throughput",
    bitcoin: "7pts",
    ethereum: "14 tps",
    polkadot: "1,500 tps",
    adrestus: "infinity tps (With subnets)",
  },
  {
    name: "Transactional Finality",
    bitcoin: "60 min",
    ethereum: "6 min",
    polkadot: "60 sec",
    adrestus: "2 sec",
  },
  {
    name: "Energy Eficient",
    bitcoin: "No ASIC-Optimal",
    ethereum: "No; GPU-Optimal",
    polkadot: "Yes; CPU-Optimal",
    adrestus: "Yes; CPU-Optimal",
  },
  {
    name: "Number of Validators",
    bitcoin: "3 Pools w/ >51% hash rate",
    ethereum: "2 Pools w/ >51% hash rate",
    polkadot: "200 nodes relay chain",
    adrestus: "Thousandsof nodes",
  },
  {
    name: "Sybil Protection",
    bitcoin: "Proof of Work",
    ethereum: "Proof of Work",
    polkadot: "Proof of Stake",
    adrestus: "Proof of Stake",
  },
  {
    name: "Safety Threshold",
    bitcoin: "51%",
    ethereum: "51%",
    polkadot: "33%",
    adrestus: "80% parameterized",
  },
];
export default tableData;
