import MiddleDoubleLetters from "../../component/MainBoard/middleDoubleLetters";

const UniqueValueSection = () => {
  return (
    <div className="pt-[100px] px-5 pb-5 lg:px-[100px] md:pt-[60px] sm:pt-[40px] sm:px-0 sm:pb-[30px]">
      <div className="max-w-[1174px]">
        <div className="flex items-center justify-center" data-aos="fade-up">
          <img
            src="./assets/img/Unique-value.png"
            alt="UniqueValue"
            className="sm:w-[342px] md:w-[357px] w-[587px]"
          ></img>
        </div>
        <div data-aos="zoom-in">
          <div className="flex w-full sm:items-center sm:justify-center items-center justify-center mt-[-35px] md:mt-[-20px] sm:mt-[-20px]">
            <MiddleDoubleLetters text="WHY ADRESTUS" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueValueSection;
