const miniHeadingLetters = ({ text }) => (
  <h2
    className="pt-3"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-delay="0"
  >
    <span className="capitalize sm:text-[18px] md:text-[14px] lg:text-[20px] text-[22px] leading-7 font-semibold text-white font-Overpass hover:text-[#31c3a8] transition-all duration-200 ease-in">
      {text}
    </span>
  </h2>
);

export default miniHeadingLetters;
