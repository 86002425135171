const constants = [
  {
    title: "Stake",
    content:
      "In Adrestus, the power is in the hands of the users holding stake. Every user receives an amount of rewards proportional to their stake for every block that is committed to the chain. We do so to encourage users to join the Algorand platform and accelerate our path to decentralization.",
    color: "firstBorder",
  },
  {
    title: "Send",
    content:
      "Adrestus is rooted in the idea that the system should allow for changes and avoid inflexible policies—enabling both the community and the protocol to evolve and this facilitates continuous evolution of the protocol and eliminates potential hard forks that could fracture the community.",

    color: "secondBorder",
  },
  {
    title: "Repeat",
    content:
      "Adrestus consensus protocol enables the users to reach consensus on anything. Not just on the next block, but also on a protocol upgrade.",
    color: "thirdBorder",
  },
];

export default constants;
