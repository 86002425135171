import MiddleDoubleLetters from "../../../component/MainBoard/middleDoubleLetters";
import HeadingLettersB from "../../../component/MainBoard/headingLettersB";
import StructureItem from "../../../component/MainBoard/structureItem";
import DivineLine from "../../../component/MainBoard/divineLine";
import { Carousel } from "react-responsive-carousel";

import { constant_downside, constant_upside } from "./constants";

const Compromise = () => {
  const combinedConstants = [...constant_upside, ...constant_downside];

  return (
    <div className="sm:m-0 sm:p-0 sm:px-5 md:px-5 md:pt-0 md:pb-[45px] lg:px-[100px] p-5 pb-[45px]">
      <div className="max-w-[1174px] mx-auto w-full flex flex-col items-center">
        <div
          className="sm:w-[325px] md:w-[347px] w-[556px] mx-auto"
          data-aos="fade-zoom-in"
        >
          <img src="./assets/img/compromise.svg" alt="compromise" />
        </div>
        <div
          className="z-10 mt-[-42px] md:mt-[-32px] sm:mt-[-28px] p-5"
          data-aos="fade-zoom-in"
        >
          <MiddleDoubleLetters text="COMPROMISE" />
        </div>
        <div data-aos="fade-zoom-in">
          <HeadingLettersB text="Build Without Compromise" />
        </div>
        <div className="text-center p-5 pt-2">
          <p className="sm:text-[12px] sm:leading-5 md:text-[10px] md:leading-[1.5] text-[14px] font-normal leading-[2] font-Poppins max-w-[831px]">
            Adrestus makes blockchain work for everyone. Our ecosystem provides
            strong security, urparalleled throughput, and smarter payments. Plug
            us in, deploy, and scale.
          </p>
        </div>
        <div className="flex flex-col sm:hidden">
          <div className="flex flex-row w-full">
            {constant_upside.map((item, index) => (
              <StructureItem
                key={index}
                src={item.src}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
          <div className="w-full flex flex-row md:justify-center justify-start">
            {constant_downside.map((item, index) => (
              <StructureItem
                key={index}
                src={item.src}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
        </div>
        <div className="sm:flex hidden w-full">
          <Carousel
            axis="horizontal"
            showThumbs={false}
            className="mx-auto w-full feature-carousel"
          >
            {combinedConstants.map((item, index) => (
              <StructureItem
                key={index}
                src={item.src}
                title={item.title}
                content={item.content}
              />
            ))}
          </Carousel>
        </div>
        <span className="pt-[70px] sm:pt-5 w-full p-5">
          <DivineLine />
        </span>
      </div>
    </div>
  );
};

export default Compromise;
