const AdvantageItem = ({ src, headTitle, contentText }) => (
  <div className="w-1/3 sm:w-full flex flex-col items-center sm:gap-3 md:gap-3 gap-7 sm:max-w-[400px] mx-auto">
    <div
      className="md:h-[84px] h-[67px] sm:h-[44px] flex items-center justify-center w-full"
      data-aos="fade-up"
    >
      <div className="w-full md:h-[44px] h-[67px] sm:h-[54px] flex justify-center m-auto">
        <img
          src={src}
          alt="IMG"
          className="md:w-[44px] max-w-[74px] w-auto h-auto sm:max-w-[54px]"
        />
      </div>
    </div>
    <div className="sm:py-3">
      <h2 className="sm:text-center">
        <span className="capitalize sm:text-[22px] md:text-[12px] text-[22px] lg:text-[17.6px] leading-[1.4] font-semibold text-white font-Overpass">
          {headTitle}
        </span>
      </h2>
    </div>
    <div className="sm:mt-0 sm:text-[12px] text-center px-5" data-aos="fade-up">
      <p className="sm:text-[12px] md:text-[9px] text-[14px] md:leading-5 sm:leading-6 leading-loose font-Poppins text-[#4d546c]">
        {contentText}
      </p>
    </div>
  </div>
);

export default AdvantageItem;
