import { useState } from "react";
import MenuItem from "../../component/Menu/menuItem";
import MenuIconItem from "../../component/Menu/menuIconItem";

const Navbar = ({ handleScrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const DropDownMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const menuItems = [
    { text: "Home", link: "#", delay: 400 },
    { text: "Whitepaper", link: "Whitepaper", delay: 500 },
    { text: "Technology", link: "Technology", delay: 600 },
    { text: "Tokenomics", link: "Tokenomics", delay: 700 },
    { text: "Roadmap", link: "Roadmap", delay: 800 },
    { text: "Staking", link: "/staking", delay: 900 },
    { text: "Contact Us", link: "/contact-us", delay: 1000 },
  ];
  return (
    <div>
      <div className=" absolute z-50 w-full lg:px-[50px] xl:px-[100px] ">
        <div className=" bg-transparent m-auto pt-[6px] pr-5 pl-5 pb-5 max-w-[1374px]">
          <div className="flex flex-row flex-wrap items-center justify-between pt-5 p-4">
            <div className="">
              <img
                src="./assets/img/logo.png"
                alt="logo"
                className="w-[74px] h-[74px] lg:w-[65px] lg:h-[65px] md:w-[60px] md:h-[60px] sm:w-[55px] sm:h-[55px]"
              ></img>
            </div>
            <div className=" visible flex flex-row items-center gap-5 h-[44px] sm:hidden md:hidden">
              {menuItems.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  onClick={(e) => handleScrollToSection(e, item.link)}
                >
                  <span
                    key={index}
                    className="hover:text-white font-semibold h-full items-center flex hover:border-b-2 border-b-[#45DFAC] select-none text-[#4D546C] transition-color duration-100 ease-in font-Poppins text-[14px] lg:text-[12px]"
                  >
                    {item.text}
                  </span>
                </a>
              ))}
              <div className="flex flex-row gap-1">
                <MenuIconItem src={"./assets/img/user-plus.svg"} size={false} />
                <MenuIconItem src={"./assets/img/setting.svg"} size={false} />
              </div>
            </div>
            <div
              id="hamburger_menu"
              className="hidden flex-end sm:flex md:flex"
              onClick={DropDownMenu}
            >
              <div className="space-y-[6px] cursor-pointer">
                <span className="block w-[30px] h-[3px] bg-white rounded"></span>
                <span className="block w-[30px] h-[3px] bg-white rounded"></span>
                <span className="block w-[30px] h-[3px] bg-white rounded"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="z-[100] fixed w-full bg-[#060c0e] overflow-hidden transition-all duration-3000 ease-in-out anim-height">
          <div className=" absolute right-8 top-12">
            <button
              type="button"
              onClick={DropDownMenu}
              className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none"
            >
              <svg
                className="h-10 w-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <ul className=" p-0 relative top-1/2 w-[80%] mx-auto my-0 transform translate-y-[-50%]">
            <li className="fadeInLeft text-center opacity-0 animate-delay-300">
              <a href="#" className="p-0">
                <span className="p-[10px] border-none inline-block">
                  <img src="./assets/img/logo.svg" alt="Logo"></img>
                </span>
              </a>
            </li>
            {menuItems.map((item, index) => {
              return (
                <MenuItem
                  link={item.link}
                  key={index}
                  text={item.text}
                  delay={item.delay}
                  handleScrollToSection={handleScrollToSection}
                />
              );
            })}
            <div className="pt-3">
              <li className="text-center fadeInLeft opacity-0 animate-delay-1100">
                <MenuIconItem src={"./assets/img/user-plus.svg"} size={true} />
              </li>
              <li className="text-center fadeInLeft opacity-0 animate-delay-1200">
                <MenuIconItem src={"./assets/img/setting.svg"} size={true} />
              </li>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
