import constants from "./constants";
import HodlornItem from "./hodlornItem";
import { Carousel } from "react-responsive-carousel";

const Hodlorn = () => {
  return (
    <div className="w-full overflow-hidden px-5 pt-[91px] pb-10 sm:pt-10 sm:pb-[60px] sm:px-5 md:py-10 lg:px-[100px]">
      <div className="max-w-[1174px] mx-auto flex flex-col">
        <div className="flex flex-col justify-center items-center">
          <div className="w-[573px] sm:w-[338px] md:w-[348px] h-auto sm:mx-auto md:ml-[-1px]">
            <img src="../assets/img/Why-Hodlorn-1.svg" alt="hodlorn" />
          </div>
          <div className="z-10 mt-[-57px] md:mt-[-42px] sm:mt-[-47px] p-5">
            <h2 className="sm:text-center">
              <span className="capitalize text-[46px] sm:text-[30px] md:text-[30px] lg:text-[36.8px] leading-[1.4] font-semibold text-white font-Overpass">
                Why Hodlorn
              </span>
            </h2>
          </div>
          <div className="sm:mt-0 sm:text-[14px] leading-snug">
            <p className="sm:text-[12px] md:text-[10px] text-[14px] font-normal leading-6 font-Poppins sm:text-center">
              Including discounted trading, advanced features, protocol access,
              and lucrative staking returns.
            </p>
          </div>
        </div>
        <div className="flex flex-row sm:hidden">
          {constants.map((item, index) => (
            <HodlornItem
              key={index}
              src={item.src}
              title={item.title}
              background={item.background}
            ></HodlornItem>
          ))}
        </div>
        <div className="sm:flex hidden w-full">
          <Carousel
            showThumbs={false}
            axis="horizontal"
            className="mx-auto w-full advantage"
          >
            {constants.map((item, index) => (
              <HodlornItem
                key={index}
                src={item.src}
                title={item.title}
                background={item.background}
              ></HodlornItem>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Hodlorn;
