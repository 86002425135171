const constant_upside = [
  {
    src: "SmartFeature1",
    title: "Including Discounted Trading",
    content:
      "Adrestus removes the technical barriers that for years undermined mainstream blockchain adoption: decentralization, scale, and security. Now you can build on a stable platform you trust.",
    borderColor: "BorderColor1",
  },
  {
    src: "SmartFeature2",
    title: "Non-Inflationary",
    content:
      "Our consensus mechanism is permissionless and PURE PROOF OF STAKE™. It ensures full participation, protection, and speed within a truly decentralized network. With blocks finalized in seconds, Adrestus transaction throughput is on par with large payment and financial networks.",

    borderColor: "BorderColor2",
  },
];
const constant_downside = [
  {
    src: "SmartFeature3",
    title: "Advanced Features",
    content:
      "Adrestus was designed to be as flexible and future-proof as possible. Adrestus’s open-source license makes it possible to create and deploy custom collateral managers for your app to interface with Adrestus on your own terms.",
    borderColor: "BorderColor3",
  },
  {
    src: "SmartFeature4",
    title: "No-Forking",
    content:
      "Adrestus is a blockchain to provide immediate transaction finality. No forking. No uncertainty.",
    borderColor: "BorderColor4",
  },
  {
    src: "SmartFeature5",
    title: "Wide Range Of Benefits",
    content:
      "Adrestus will give holders a range of benefits ,plus additional functionality that will add further value and incentivize holding : including discounted trading, advanced features, protocol access, and lucrative staking returns.",
    borderColor: "BorderColor5",
  },
];

export { constant_downside, constant_upside };
