const Intro = () => {
  return (
    <>
      <div className="w-full staking-panel py-[223px] px-5 relative xl:pt-[170px] xl:pb-[130px] lg:px-[100px] lg:pt-[170px] lg:pb-[130px] md:pt-[271px] md:pb-[175px] sm:pt-[223px] sm:pb-[210px]">
        <div className="max-w-[1174px] mx-auto min-w-0">
          <div className="w-full relative z-10 m-0 p-0" data-aos="fade-zoom-in">
            <div className="m-5 sm:mb-[23px]">
              <h1 className="font-Poppins font-semibold text-center m-0 p-0 leading-[1.4] tracking-[0] text-[61px] lg:text-[48.8px] sm:text-[46px] text-white">
                <span>Staking</span>
              </h1>
            </div>
            <div className="m-5 mt-[-10px] sm:mt-[-5px]">
              <div className=" text-white/70 text-center text-[16px] font-Poppins font-normal leading-[2] sm:leading-[25px]">
                <p>
                  Earn money making devices probabilistically impossible to
                  hack.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
