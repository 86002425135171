import ContentLetters from "../../../component/MainBoard/contentletters";
import DoubleLetters from "../../../component/MainBoard/doubleletters";
import HeadingLetters from "../../../component/MainBoard/headingletters";
import MiddleDoubleLetters from "../../../component/MainBoard/middleDoubleLetters";
import MiniContentLetters from "../../../component/MainBoard/miniContentLetters";
import MiniHeaindgLetters from "../../../component/MainBoard/miniHeadingLetters";

const TechnologySection = () => {
  return (
    <div className="flex flex-col py-0 pb-[20px] md:px-10 sm:px-5 max-w-[1174px] m-auto">
      <div className="overflow-hidden sm:pt-[60px] sm:pb-5 md:pt-[120px] m-0 px-auto lg:px-[100px] pt-[140px] flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <MiddleDoubleLetters text="TECHNOLOGY" />
          <div
            className="py-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="0"
          >
            <h2 className="sm:text-center">
              <span className="capitalize sm:text-[30px] md:text-[30px] text-[46px] lg:text-[36px] font-semibold text-white font-Overpass">
                Technology Built For Internet Scale
              </span>
            </h2>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col justify-start items-between sm:max-w-[400px] md:pb-[60px] pb-5">
          <div
            className="flex flex-col w-[38%] sm:w-full"
            data-aos="fade-right"
          >
            <div className="p-5">
              <img src="./assets/img/technology1.gif" alt="tech" />
            </div>
            <div className="flex flex-col md:gap-1">
              <DoubleLetters text="THE OPTIMAL APPROACH" />
              <HeadingLetters text="Adaptive State Sharding." />
              <ContentLetters text="The optimal approach to blockchain sharding needs to take into consideration advantages from all three sharding types: State, Transactions & Network." />
              <span className="md:py-1 sm:py-2"></span>
              <ContentLetters text="Adrestus approach to increased throughput called “Adaptive State Sharding”. Combines all three-zone types into a solution that improves communication inside the zones and dramatically increases performance through parallel processing." />
            </div>
          </div>
          <div
            className="md:pt-[0px] pt-[130px] w-[24%] sm:w-full flex justify-center sm:hidden xl:h-[1px] 2xl:h-[1px]"
            data-aos="fade-up"
          >
            <img
              src="./assets/img/ourTools.svg"
              alt="tools"
              className="md:w-[62px] w-[115px]"
            />
          </div>
          <div className="sm:flex hidden pt-[60px] w-full" data-aos="fade-up">
            <img
              src="./assets/img/ourToolsOrientation.svg"
              alt="tools"
              className="w-full"
            />
          </div>
          <div
            className="flex flex-col w-[38%] md:pt-[60px] sm:w-full pt-[90px] md:gap-1"
            data-aos="fade-left"
          >
            <DoubleLetters text="THE OPTIMAL APPROACH" />
            <HeadingLetters text="Secure Proof Of Stake." />
            <ContentLetters text="Adrestus has proposed a novel approach to consensus called “Effective Proof of Stake” which eliminates PoW computational waste, and combines eligibility through stake and rating with random validator selection, and an optimal dimension for the consensus group." />
            <span className="md:py-1 sm:py-2"></span>
            <ContentLetters text="The BFT-like consensus protocol maintains a high security level through random sampling of the consensus group, and random reshuffling of nodes into other shards." />
            <div className="p-5">
              <img src="./assets/img/technology2.gif" alt="tech" />
            </div>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col py-[60px] sm:max-w-[400px] gap-0 w-full">
          <div className="sm:w-full w-1/2 flex flex-col sm:gap-5 gap-3 justify-center">
            <div
              className="md:py-0 md:pb-[11px] firstBorder borderImage py-[14px] pr-5 pl-4 techItem w-full sm:pb-5"
              data-aos="fade-down"
            >
              <MiniHeaindgLetters text="Almost Instant Transfers" />
              <MiniContentLetters text="Transactions on Adrestus are finalized in two second and cost a fraction of a cent." />
            </div>
            <div
              className="md:py-0 md:pb-[11px] secondBorder borderImage py-[14px] pr-5 pl-4 techItem w-full sm:pb-5"
              data-aos="fade-down"
            >
              <MiniHeaindgLetters text="Simply Secure" />
              <MiniContentLetters text="Adrestus validator nodes form a global, trustless, and leaderless Proof-of-Stake network." />
            </div>
            <div
              className="md:py-0 md:pb-[11px] thirdBorder borderImage py-[14px] pr-5 pl-4 techItem w-full sm:pb-5"
              data-aos="fade-down"
            >
              <MiniHeaindgLetters text="Highly Scalable" />
              <MiniContentLetters text="Adrestus can process thousands of transactions per seconds and scale to thousands of nodes." />
            </div>
          </div>
          <div
            className="sm:w-full w-1/2 sm:pt-10 flex justify-center items-center"
            data-aos="fade-zoom-in"
          >
            <img
              src="./assets/img/tecDialog.svg"
              className="lg:w-[420px]"
              alt="Tech Dialog"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologySection;
