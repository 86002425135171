import { Link } from "react-router-dom";

const Extra = () => {
  return (
    <div>
      <div className="mx-auto">
        <div className="px-5 pt-20 pb-[100px] lg:px-[100px] md:pt-[60px] sm:px-0 sm:pt-0 sm:pb-[60px]">
          <div className="mx-auto max-w-[1174px] min-w-0">
            <div className="w-full">
              <div className="staking-board pt-[30px] px-[79px] pb-[14px] md:pt-[30px] md:pb-[10px] md:px-[18px] sm:px-1 sm:pb-0 sm:pt-3 sm:mx-[17px]">
                <div className="m-5">
                  <div className="text-[14px] font-Poppins text-white text-center mt-0 mb-4 leading-[2] font-normal md:text-[16px] sm:text-[14px] sm:leading-[22px]">
                    <p data-aos="fade-zoom-in">
                      The primary benefit of staking is that you earn more
                      crypto, and interest rates can be very generous. In some
                      cases, you can earn more than 10% or 20% per year. It's
                      potentially a very profitable way to invest your money.
                      And, the only thing you need is crypto that uses the
                      proof-of-stake model. Staking is also a way of supporting
                      the Adrestus blockchain which rely on holders staking to
                      verify transactions and keep everything running smoothly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="staking-access-board p-5 py-10 relative m-0">
          <div className="mx-auto max-w-[670px] min-w-0">
            <div className="w-full">
              <div className="m-0 p-0">
                <div className="p-5 sm:pb-[-5px]" data-aos="fade-zoom-in">
                  <h2 className="m-0 p-0 text-white text-center leading-[1.4] tracking-[0] font-medium font-Poppins text-[39px] lg:text-[31.2px] md:text-[30px] sm:text-[30px]">
                    <span>Get The Whitepaper Here!</span>
                  </h2>
                </div>
                <div className="p-5 pt-[-10px] sm:pt-5" data-aos="fade-zoom-in">
                  <div className="mt-0 mb-4 font-Poppins text-white/70 font-normal leading-[2] text-center text-[16px] md:leading-[22px] md:text-[14px] sm:leading-[22px] sm:text-[14px]">
                    <p>
                      The optimal approach to blockchain sharding needs to take
                      into consideration advantages from all three sharding.
                    </p>
                  </div>
                </div>
                <div className="p-5 pt-5 sm:pt-[-8px]" data-aos="fade-zoom-in">
                  <div className="text-center">
                    <Link
                      to="https://www.sciencedirect.com/science/article/pii/S2096720922000343?v=s5)"
                      className="w-[370px] md:w-[251px] md:h-[50px] sm:w-[201px] sm:h-[50px] colorBtn font-normal font-Poppins text-[#081113] text-[20px] py-[18px] leading-[1] px-8 md:text-[16px] sm:text-[12px]"
                    >
                      <span>Access The WhitePaper</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Extra;
