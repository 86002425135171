import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DoubleLetters from "../../../component/MainBoard/doubleletters";
import RoadmapItem from "./roadmapItem";

import { constants_top, constants_bottom } from "./constants";

const Roadmap = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const triggerElements = document.querySelectorAll(".roadmap-row .fl-html");
    const targetElement = document.querySelector(".roadmap-wrapper-slide");
    const roadmapElOffset = targetElement.getBoundingClientRect().left;
    const roadmapElWidth = targetElement.offsetWidth;
    const finishRoadmap = roadmapElWidth - window.innerWidth;

    triggerElements.forEach((triggerElement) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: triggerElement,
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
      });
      tl.fromTo(
        targetElement,
        {
          left: roadmapElOffset,
          duration: 1,
        },
        {
          left: -finishRoadmap,
          duration: 1,
        }
      );
    });

    window.addEventListener("scroll", () => {
      let roadmapPosition = targetElement.getBoundingClientRect().left;
      if (
        document.querySelector(".roadmap-year-content h3") !== null &&
        document.querySelector(".roadmap-year-content p") !== null &&
        document.querySelector(".roadmap-year-content h2 span") !== null
      ) {
        if (roadmapPosition < -3000) {
          document.querySelector(".roadmap-year-content h3").innerHTML =
            "USABILITY";
          document.querySelector(".roadmap-year-content p").innerHTML =
            "Improve the Adrestus payment experience to ensure that it is instant and reliable. Transactions must be received instantly and be completely secure within seconds";
          document.querySelector(".roadmap-year-content h2 span").innerHTML =
            "2024";
        } else {
          document.querySelector(".roadmap-year-content h3").innerHTML =
            "SCALING";
          document.querySelector(".roadmap-year-content p").innerHTML =
            "Enable Adrestus to scale from 1000tx/s to over 100,000 tx/s. Mass parallelization is necessary to achieve this scale";
          document.querySelector(".roadmap-year-content h2 span").innerHTML =
            "2023";
        }
      }
    });
  }, []);

  return (
    <div id="roadmap" className="w-full p-0 m-0 mt-5 roadmap-row">
      <div className="fl-html">
        <div className="roadmap-wrapper">
          <div className="roadmap-text flex flex-col items-center w-full ">
            <img
              src="./assets/img/Roadmap.svg"
              alt="Roadmap
              "
              className="sm:w-[300px] h-auto w-[410px] mx-auto"
            />
            <div className="sm:mt-[-22px] mt-[-38px]">
              <DoubleLetters text="ROADMAP" />
            </div>
            <h2 className="font-Overpass text-white text-[46px] md:text-[30px] sm:text-[36px] tracking-[0px] font-semibold">
              Our Roadmap
            </h2>
          </div>
          <div className="roadmap-slide-show">
            <div className="roadmap-year">
              <div className="roadmap-year-content">
                <h3 className="font-Overpass text-white font-semibold leading-[1.4] text-[30px] tracking-[6px] mb-2 sm:text-[18px] sm:tracking-[3px] md:text-[18px] md:tracking-[3px] lg:text-[20px] lg:tracking-[3px] 2xl:text-[28px] 2xl:tracking-[5px] xl:text-[24px] xl:tracking-[3px]">
                  {" "}
                </h3>
                <p className="font-Poppins mb-4 max-w-[303px] w-full min-h-[112px] text-[14px] leading-[1.6] 2xl:max-w-[212px] 2xl:min-h-[95px] 2xl:text-[12px] xl:max-w-[174px] xl:min-h-[95px] xl:text-[10px] lg:max-w-[174px] lg:min-h-[80px] lg:text-[10px] md:max-w-[174px] md:min-h-[80px] md:text-[10px] sm:max-w-[174px] sm:min-h-[80px] sm:text-[10px]"></p>
                <div className="linear-heading">
                  <h2 className="fl-heading font-Overpass mb-2 text-[46px] tracking-[0px] leading-[1.4] font-semibold 2xl:text-[40px] xl:text-[36px] lg:text-[32px] md:text-[25px] sm:text-[25px]">
                    <span></span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="roadmap-wrapper-slide-parent">
              <div className="roadmap-wrapper-slide">
                <div className="roadmap-wrapper-top">
                  {constants_top.map((item, index) => (
                    <RoadmapItem
                      key={index}
                      title={item.title}
                      content={item.content}
                      suffix={item.suffix}
                    ></RoadmapItem>
                  ))}
                </div>
                <div className="separator"></div>
                <div className="roadmap-wrapper-bottom">
                  {constants_bottom.map((item, index) => (
                    <RoadmapItem
                      key={index}
                      title={item.title}
                      content={item.content}
                      suffix={item.suffix}
                    ></RoadmapItem>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
