import MiddleDoubleLetters from "../../../component/MainBoard/middleDoubleLetters";
import StructureItem from "../../../component/MainBoard/structureItem";
import { constant_upside, constant_downside } from "./constants";
import { Carousel } from "react-responsive-carousel";

const FeatureSection = () => {
  const combinedConstants = [...constant_upside, ...constant_downside];
  return (
    <div className="w-full sm:pt-10 sm:pb-[70px] sm:px-5 md:py-[60px] md:px-5 pt-5 pb-[99px] px-5 lg:px-[100px]">
      <div className="max-w-[1174px] flex flex-col p-0 items-center m-auto sm:max-w-[765px]">
        <div className="sm:w-[200px] md:w-[211px] w-[340px]" data-aos="fade-up">
          <img src="../assets/img/Feature.svg" alt="Feature" />
        </div>
        <div
          className="z-10 mt-[-42px] md:mt-[-32px] sm:mt-[-28px] p-5"
          data-aos="fade-up"
        >
          <MiddleDoubleLetters text="STRUCTURE AND ROLES" />
        </div>
        <div
          className="pb-10 pt-[25px] md:pb-5 sm:pb-5 sm:pt-[5px] md:pt-[5px]"
          data-aos="fade-up"
        >
          <h2 className="sm:text-center">
            <span className="capitalize sm:text-[30px] md:text-[27px] text-[46px] lg:text-[36px] leading-[1.4] font-semibold text-white font-Overpass">
              Network Structure And Roles
            </span>
          </h2>
        </div>
        <div className="flex flex-col sm:hidden">
          <div
            className="flex flex-row sm:flex-col sm:items-center sm:justify-center"
            data-aos="fade-up"
          >
            {constant_upside.map((item, index) => {
              return (
                <StructureItem
                  key={index}
                  src={item.src}
                  title={item.title}
                  content={item.content}
                />
              );
            })}
          </div>
          <div
            className="flex flex-row sm:flex-col sm:items-center sm:justify-center"
            data-aos="fade-up"
          >
            {constant_downside.map((item, index) => {
              return (
                <StructureItem
                  key={index}
                  src={item.src}
                  title={item.title}
                  content={item.content}
                />
              );
            })}
          </div>
        </div>
        <div className="sm:flex hidden w-full">
          <Carousel
            showThumbs={false}
            axis="horizontal"
            className="mx-auto w-full feature-carousel"
          >
            {combinedConstants.map((item, index) => {
              return (
                <StructureItem
                  key={index}
                  src={item.src}
                  title={item.title}
                  content={item.content}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
