const constants = [
  {
    src: "./assets/img/hodlorn-1.svg",
    title: "Discounted Trading",
    background: "hodlorn-back1",
  },
  {
    src: "./assets/img/hodlorn-2.svg",
    title: "Staking Returns",
    background: "hodlorn-back2",
  },
  {
    src: "./assets/img/hodlorn-3.svg",
    title: "Priority Access",
    background: "hodlorn-back3",
  },
  {
    src: "./assets/img/hodlorn-4.svg",
    title: "Advanced Features",
    background: "hodlorn-back4",
  },
];

export default constants;
