const WhitePaper = () => {
  return (
    <div className="w-full px-5 py-[23px] sm:py-10 sm:px-0 md:px-10 lg:px-[100px] xl:px-[100px]">
      <div className="max-w-[1174px] mx-auto flex flex-row sm:flex-col justify-between overflow-x-hidden">
        <div
          className=" w-1/2 sm:w-full flex flex-col justify-center sm:items-center gap-3"
          data-aos="fade-right"
        >
          <div className="py-1">
            <h2 className="sm:text-[30px] md:text-[22px] lg:text-[36px] text-[46px] leading-snug font-semibold">
              <span className="letter-color capitalize font-Overpass">
                The Adrestus whitepaper
              </span>
            </h2>
          </div>
          <div className="py-2">
            <h2 className="sm:text-center">
              <span className="capitalize sm:text-[20px] md:text-[14px] lg:text-[24px] text-[30px] leading-[1.4] font-semibold text-white font-Overpass">
                dives further into the technical capabilities and advanced
                features of the Amp smart payments
              </span>
            </h2>
          </div>
          <div className="py-2">
            <button className="sm:w-[230px] sm:h-[50px] md:w-[230px] md:h-[50px] w-[291px] h-[66px] rounded-[33px] colorBtn">
              <a
                href="https://github.com/Adrestus-net/Adrestus"
                className="flex justify-center"
              >
                <span className="text-[#081113] text-[16px] md:text-[12px] sm:text-[12px] left-5 font-semibold text-center font-Poppins">
                  Access The White Paper
                </span>
              </a>
            </button>
          </div>
        </div>
        <div
          className=" w-1/2 sm:w-full p-5 flex justify-center items-center"
          data-aos="fade-left"
        >
          <div className="flex justify-center items-center">
            <img
              src="./assets/img/whitepaper.png"
              alt="whitepaper"
              className="w-80"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitePaper;
