const MenuItem = ({ link, text, delay, handleScrollToSection }) => (
  <li className={`text-center fadeInLeft opacity-0 animate-delay-${delay}`}>
    <a
      href={link}
      className="p-0"
      onClick={(e) => handleScrollToSection(e, link)}
    >
      <span className="p-[10px] border-none inline-block hover:text-white text-base font-medium h-full items-center hover:border-b-2 border-b-[#45DFAC] select-none text-[#4D546C] transition-color duration-100 ease-in">
        {text}
      </span>
    </a>
  </li>
);

export default MenuItem;
