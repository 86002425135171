const DoubleLetters = ({ text }) => (
  <div className="w-full relative flex sm:justify-center justify-start items-center">
    <span className=" absolute sm:text-[14px] md:text-[10px] text-[18px] tracking-[.3em] top-[1.5px] md:left-1 block leading-none text-[#39CCEC] filter-blur">
      {text}
    </span>
    <h3 className="sm:text-[14px] md:text-[10px] text-[18px] tracking-[.3em] relative z-10 text-[#39CCEC] font-semibold leading-snug font-Overpass">
      {text}
    </h3>
  </div>
);

export default DoubleLetters;
