const constants_Introlink = [
  { title: "Home", link: "#" },
  { title: "Whitepaper", link: "Whitepaper" },
  { title: "Technology", link: "Technology" },
  { title: "Tokenomics", link: "Tokenomics" },
  { title: "Roadmap", link: "Roadmap" },
];

const constants_Funclink = [
  { title: "Staking", link: "/staking" },
  { title: "Contact Us", link: "/contact-us" },
];

const constants_SocialLink = [
  {
    src: "facebook.svg",
    link: "/",
  },
  {
    src: "discord.svg",
    link: "/",
  },
  {
    src: "twitter.svg",
    link: "/",
  },
  {
    src: "youtube.svg",
    link: "/",
  },
  {
    src: "telegram.svg",
    link: "/",
  },
  {
    src: "instagram.svg",
    link: "/",
  },
];

export { constants_Introlink, constants_Funclink, constants_SocialLink };
