import BenefitItem from "./benefitItem";
const Benefit = () => {
  return (
    <div className="mx-auto w-full">
      <div className="m-0 p-5 pt-0 lg:px-[100px] sm:pt-0 sm:px-0 sm:pb-5">
        <div className="max-w-[1174px] min-w-0 mx-auto flex flex-col">
          <div className="w-full p-0 m-0">
            <div className="m-5 mb-0">
              <div className="relative text-center" data-aos="fade-zoom-in">
                <div className="inline-block relative max-w-full w-[789px] md:w-[600px] sm:w-[354px] text-center z-0">
                  <img
                    src="./assets/img/why_we_are.svg"
                    alt="whyweare"
                    className="mx-auto"
                  />
                </div>
              </div>
            </div>
            <div
              className="m-5 mt-[-36px] md:mt-[-20px] sm:mt-[-30px]"
              data-aos="fade-zoom-in"
            >
              <h2 className="z-10 text-white p-0 m-0 font-Overpass leading-1.4 tracking-[0] font-medium text-[49px] text-center lg:text-[39.2px] md:text-[30px] sm:text-[30px] sm:leading-[42px]">
                <span>Benefits of staking crypto</span>
              </h2>
            </div>
            <div className="m-5 mt-[-7px] sm:my-0" data-aos="fade-zoom-in">
              <div className="font-Poppins text-center text-white/70 font-normal leading-[26px] text-[14px] lg:text-[14px] md:text-[14px] sm:text-[16px]">
                <p>Here are the benefits of cryptocurrency staking.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full sm:flex-col">
            <div className="w-1/3 sm:w-full sm:max-w-[400px] sm:mx-auto">
              <div data-aos="fade-right">
                <BenefitItem
                  src="./assets/img/staking01.png"
                  text="It's an easy way to earn interest on your cryptocurrency holdings"
                />
              </div>
              <div data-aos="fade-right">
                <BenefitItem
                  src="./assets/img/staking02.png"
                  text="You're helping to maintain the security and efficiency of the Adrestus."
                />
              </div>
            </div>
            <div className="w-1/3 sm:w-full m-0 p-0">
              <div className="hidden md:block lg:block">
                <div className="m-5 mt-[90px]">
                  <div className="relative text-center">
                    <div
                      className="inline-block relative max-w-full"
                      data-aos="fade-zoom-in"
                    >
                      <img
                        src="./assets/img/separator.png"
                        alt="separator"
                        className="max-w-full h-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/3 sm:w-full sm:max-w-[400px] sm:mx-auto">
              <div data-aos="fade-left">
                <BenefitItem
                  src="./assets/img/staking03.png"
                  text="You don't need any equipment for crypto staking like you would for crypto mining."
                />
              </div>
              <div data-aos="fade-left">
                <BenefitItem
                  src="./assets/img/staking04.png"
                  text="It's more environmentally friendly than crypto mining."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
