const constant_upside = [
  {
    src: "Structure_Zones.png",
    title: "Zones",
    content:
      "The Zones are smaller partitions of the Adrestus network and are used for scaling: each zone is responsible for a portion of the state (accounts, payments, blockchain) and transaction processing, so that every zone can process only a fraction of the transactions in parallel with other shards.",
  },
  {
    src: "Structure_Metachain.png",
    title: "Metachain",
    content:
      "The Metachain is the blockchain that runs in a special zone, where the main responsibilities are not processing of transactions, but notarizing and finalizing the processed zone block headers, facilitating communication between zones, storing and maintaining a registry of validators, triggering new epochs, processing fisherman challenges, rewarding and slashing.",
  },
  {
    src: "Structure_Nodes.png",
    title: "Nodes",
    content:
      "The Node is a computer, smartphone or server, running the Adrestus client and relaying messages received from its peers. Nodes can fulfill roles of Validators, Organizers or Fisherman providing different support levels to the network and earning proportional rewards.",
  },
];

const constant_downside = [
  {
    src: "Structure_Validator.png",
    title: "Validator",
    content:
      "Validators are nodes on the Adrestus network that process transactions and secure the network by participating in the consensus mechanism, while earning rewards from the protocol and transaction fees. In order to become part of the Adrestus network, a validator needs to put up collateral in the form of ADR tokens, which are staked to align the incentives between validators and network goals. Validators stand to lose, their stake if they collude to disrupt the network.",
  },
  {
    src: "Structure_Organizers.png",
    title: "Organizers",
    content:
      "Organizers are nodes on the Adrestus network that act as leaders on the predetermined zone that is assigned. They are responsible for packaging transactions in blocks and proposing them to the other node of the zone by participating in the consensus mechanism while earning rewards from the protocol and transaction fees. Organizers are validators with additional capabilities. Organizers that are not comfοrm with protocol lose, their stake if they collude to disrupt the network.",
  },
  {
    src: "Structure_Fisherman.png",
    title: "Fisherman",
    content:
      "A node which verifies the validity of blocks after they have been proposed. They challenge invalid blocks resulted from adversity of malicious actors and are rewarded for their service. The Fisherman role can be fulfilled by validators which are not part of the current consensus round or by organizers.",
  },
];

export { constant_upside, constant_downside };
