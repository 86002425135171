const constants = [
  {
    src: "./assets/img/Advantage2-1.png",
    title: "Staking",
    content:
      "Game theory incentivizes token holders to behave in honest ways. Good actors are rewarded by this mechanism whilst bad actors will lose their stake in the network. This ensures the network stays secure.",
  },
  {
    src: "./assets/img/Advantage2-2.png",
    title: "Bonding",
    content:
      "New parachains are added by bonding tokens. Outdated or non-useful parachains are removed by removing bonded tokens. This is a form of proof of stake.",
  },
  {
    src: "./assets/img/Advantage2-3.png",
    title: "Governance",
    content:
      "Adrestus token holders have complete control over the protocol. All privileges which on other platforms are ex clusive to miners, will be given to the Relay Chain participants (ADR holders), including managing exceptional events such as protocol upgrades and fixes.",
  },
];

export default constants;
