const HodlornItem = ({ src, title, background }) => (
  <div
    data-aos="fade-zoom-in"
    className={`sm:w-full w-1/4 flex flex-col justify-between ${background} `}
  >
    <div className="w-full pb-[19px] m-[15px] sm:pb-10 sm:m-0 md:pb-[3px] md:m-[9px] hodlorn-board">
      <div className="w-full">
        <div className="m-5 sm:mb-[23px] md:mb-[15px]">
          <div className="max-w-full h-[71px] md:h-[65px] flex justify-center">
            <img src={src} alt={src} />
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="m-5 mt-[6px]">
          <h4 className="sm:text-[18px] md:text-[12px] lg:text-[15.6px] text-[20px] text-center leading-[1.4] tracking-normal font-semibold text-white font-Overpass">
            <span>{title}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
);

export default HodlornItem;
