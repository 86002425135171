import DivineLine from "../../../component/MainBoard/divineLine";
import constants from "./constants";

const ActionSection = () => {
  return (
    <>
      <div className="px-5 pt-[45px] pb-[68px] sm:py-[46px] sm:px-0 md:px-5 md:pt-[45px] md:pb-[68px] lg:px-[100px] lg:pt-[45px] lg:pb-[68px]">
        <div className=" max-w-[1320px] flex flex-row sm:flex-col mx-auto">
          <div className=" w-2/5 sm:w-full md:w-[58%] p-5 sm:pb-[35px]">
            <lottie-player
              src="./assets/lotties/third_lottie.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className=" w-3/5 sm:w-full md:w-[58%] pl-[11%] py-0 sm:pl-0 md:pl-0 lg:pl-0 xl:pl-[6%] flex flex-col justify-between">
            {constants.map((item, index) => (
              <div
                key={index}
                data-aos="fade-up"
                className={`py-2 mx-5 mb-5 mt-0 pr-5 pl-4 sm:py-[11px] sm:px-[5px] sm:mx-5 md:py-0 md:pr-5 md:pl-[2px] sm:mb-5 md:mb-[11px] md:mx-5 md:mt-0 borderImage ${item.color}`}
              >
                <div className="px-5 flex flex-row justify-between items-center">
                  <a href="https://www.adrestus.net/">
                    <h2 className="pt-3">
                      <span className="capitalize sm:text-[18px] md:text-[14px] lg:text-[18px] text-[22px] leading-7 font-semibold text-white font-Overpass hover:text-[#31c3a8] transition-all duration-200 ease-in">
                        {item.title}
                      </span>
                    </h2>
                  </a>
                  <span className="pr-2">
                    <img
                      src="./assets/img/rightArrow.svg"
                      alt="rightArrow"
                      className="w-[23px] h-[12px] opacity-50 hover:opacity-100 transition-all duration-200 ease-in-out"
                    ></img>
                  </span>
                </div>
                <div className="px-5 pb-4">
                  <div className="sm:mt-0 sm:text-[14px] leading-snug py-1">
                    <p className="sm:text-[12px] sm:leading-6 md:text-[10px] md:leading-[18px] lg:text-[12px] lg:leading-[26px] text-[16px] font-normal leading-6 w-full font-Poppins">
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-[121px] pb-10 sm:pt-10 sm:pb-0 md:py-[60px] md:px-5 lg:px-[100px]">
        <div className="max-w-[1174px] mx-auto flex flex-col">
          <div className="flex flex-row sm:flex-col w-full">
            <div
              className="w-[52%] sm:w-full flex flex-col justify-center"
              data-aos="fade-zoom-in"
            >
              <div className="sm:w-[345px] md:w-[338px] lg:w-full max-w-[590px] h-auto sm:mx-auto md:ml-[-1px] ml-[-40px]">
                <img src="../assets/img/Safe-Smart.svg" alt="Feature" />
              </div>
              <div className="z-10 mt-[-42px] md:mt-[-32px] sm:mt-[-28px] p-5">
                <h2 className="sm:text-center">
                  <span className="capitalize sm:text-[30px] md:text-[26px] text-[46px] lg:text-[34px] leading-[1.4] font-semibold text-white font-Overpass">
                    Safer Smart Payments
                  </span>
                </h2>
              </div>
              <div className="font-Poppins sm:text-[12px] sm:leading-6 sm:text-center md:text-[10px] md:leading-4 text-[14px] leading-[23px] px-5">
                <p className="w-full my-0">
                  By utilising the blockchain and smart payments, developers are
                  able to build applications that are more secure, more
                  reliable, and ultimately more accessible for the wider market.
                  Smart payments built on the blockchain will enable safe
                  transactions without the need for an intermediary becoming
                  involved in the middle of the process – this reduces the time
                  needed to transact, shortens the chain of possession, and
                  makes automated secure transactions a real, tangible
                  possibility
                </p>
              </div>
            </div>
            <div
              className="w-[48%] sm:w-full sm:max-w-[400px] p-5 flex items-center justify-center sm:mx-auto"
              data-aos="fade-zoom-in"
            >
              <lottie-player
                src="./assets/lotties/fourth_lottie.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "100%" }}
                direction="1"
                mode="normal"
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
          <span className="pt-[70px] sm:pt-5 w-full p-5">
            <DivineLine />
          </span>
        </div>
      </div>
    </>
  );
};

export default ActionSection;
