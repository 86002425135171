import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./layout/navbar/navbar";
import Footer from "./layout/footer/footer";
import PostConent from "./layout/mainboard/postcontent";
import WhitePaper from "./layout/mainboard/whitepaper";
import UniqueValueSection from "./layout/mainboard/uniqueValueSection";
import ViewPoint from "./layout/mainboard/viewpoint";
import Advantage from "./layout/mainboard/advantage";
import Advantage2 from "./layout/mainboard/advantage2";
import AccessWhitePaper from "./layout/mainboard/accessWhitepaper";
import TechnologySection from "./layout/mainboard/technology";
import CompareChain from "./layout/mainboard/compareChain";
import FeatureSection from "./layout/mainboard/feature";
import ActionSection from "./layout/mainboard/actionSection";
import Compromise from "./layout/mainboard/compromise";
import PaymentFeature from "./layout/mainboard/paymentFeature";
import Tokenmoics from "./layout/mainboard/tokenomics";
import Roadmap from "./layout/mainboard/roadmap";
import Hodlorn from "./layout/mainboard/hodlorn";
import Staking from "./layout/staking";
import ContactUs from "./layout/contactus";

import "./App.css";

function App() {
  const handleScrollToSection = (e, id) => {
    e.preventDefault();
    if (id === "/contact-us") window.location.href = "/contact-us";
    else if (id === "/staking") window.location.href = "/staking";
    else {
      if (
        window.location.pathname === "/staking" ||
        window.location.pathname === "/contact-us"
      )
        window.location.href = "/";
      else {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Router>
      <>
        <Navbar handleScrollToSection={handleScrollToSection} />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <div id="mainboard">
                  <div id="#">
                    <PostConent />
                  </div>
                  <div id="Whitepaper">
                    <WhitePaper />
                  </div>
                  <UniqueValueSection />
                  <ViewPoint />
                  <Advantage />
                  <Advantage2 />
                  <AccessWhitePaper />
                  <div id="Technology">
                    <TechnologySection />
                  </div>
                  <CompareChain />
                  <FeatureSection />
                  <ActionSection />
                  <Compromise />
                  <PaymentFeature />
                  <div id="Tokenomics">
                    <Tokenmoics />
                  </div>
                  <div id="Roadmap">
                    <Roadmap />
                  </div>
                  <Hodlorn />
                  <Footer handleScrollToSection={handleScrollToSection} />
                </div>
              </>
            }
          />
          <Route
            path="/staking"
            element={
              <div id="staking">
                <Staking />
                <Footer handleScrollToSection={handleScrollToSection} />
              </div>
            }
          />
          <Route
            path="/contact-us"
            element={
              <div id="contact-us">
                <ContactUs />
                <Footer handleScrollToSection={handleScrollToSection} />
              </div>
            }
          />
        </Routes>
      </>
    </Router>
  );
}

export default App;
