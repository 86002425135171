const AdvantageItem2 = ({ src, headTitle, contentText }) => (
  <div className="w-1/3 sm:w-full flex flex-col items-center gap-2">
    <div
      className="flex items-center justify-center w-full p-5"
      data-aos="fade-zoom-in"
    >
      <div className="max-w-full flex justify-center m-auto">
        <img
          src={src}
          alt="IMG"
          className="sm:w-[228px] md:w-[172px] max-w-full h-auto"
        />
      </div>
    </div>
    <div className="sm:py-3" data-aos="fade-zoom-in">
      <h2 className="sm:text-center">
        <span className="capitalize sm:text-[22px] md:text-[14px] text-[22px] lg:text-[18px] leading-[1.4] font-semibold text-white font-Overpass">
          {headTitle}
        </span>
      </h2>
    </div>
    <div
      className="sm:mt-0 sm:text-[12px] text-center px-5"
      data-aos="fade-zoom-in"
    >
      <p className="sm:text-[12px] md:text-[10px] text-[14px] font-normal md:leading-5 sm:leading-6 leading-loose font-Poppins max-w-[335px] text-[#4d546c]">
        {contentText}
      </p>
    </div>
  </div>
);

export default AdvantageItem2;
