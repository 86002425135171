const StructureItem = ({ src, title, content }) => (
  <div
    className="sm:max-w-[400px] sm:w-full w-1/3 sm:px-0 sm:py-0 px-[7px] py-[5px] flex flex-col sm:justify-center"
    data-aos="fade-up"
  >
    <div className="px-5 pt-5 pb-2 sm:pb-0 md:pb-0 sm:mx-auto">
      <img
        src={`./assets/img/${src}`}
        className="sm:w-[94px] md:w-[58px] w-[95px]"
        alt={src}
      ></img>
    </div>
    <div className="sm:pt-[26px] sm:pb-[9px] md:pt-[15px] md:pb-[13px] px-5 pt-5 pb-[9px] sm:mx-auto">
      <h4 className="font-Overpass sm:text-[22px] md:text-[14px] lg:text-[18px] text-[22px] text-white font-semibold">
        {title}
      </h4>
    </div>
    <div className="font-Poppins sm:text-[12px] sm:leading-6 sm:text-center md:text-[10px] md:leading-4 text-[12px] leading-[23px] px-5 pb-5">
      <p className="w-full max-w-[329px] mx-auto my-0 text-[#4d546c]">
        {content}
      </p>
    </div>
  </div>
);

export default StructureItem;
