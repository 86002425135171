import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  constants_Introlink,
  constants_Funclink,
  constants_SocialLink,
} from "./constants";

const Footer = ({ handleScrollToSection }) => {
  const [contactState, setContactState] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/contact-us") setContactState(true);
    else setContactState(false);
  }, [window.location.pathname]);

  return (
    <footer
      className={`w-full flex flex-col ${contactState ? "bg-[#060C0E]" : ""}`}
    >
      {/* <footer className="w-full flex flex-col bg-[#060C0E]"> */}
      <div className="p-5 pt-[21px] pb-0 sm:pt-[30px] sm:px-0 sm:pb-[14px] md:pt-[21px] md:pb-0 lg:px-[100px] ">
        <div className="max-w-[1174px] mx-auto flex flex-row sm:flex-col">
          <div className="w-1/2 sm:w-full md:w-[49%] flex flex-col">
            <div className="w-full p-5">
              <div className="text-left w-full">
                <div className="w-20 md:w-[49px] sm:mx-auto">
                  <a href="/">
                    <img src="./assets/img/logo.png" alt="logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full p-5 sm:pt-1 sm:pb-[15px] md:pt-1">
              <div className="text-[14px] leading-[2] sm:text-[12px] sm:text-center md:text-[9px] md:leading-4 font-Poppins">
                <p className="md:max-w-[220px] lg:w-[360px]">
                  Adrestus is an open sourced public blockchain platform,
                  leveraging the security of the account model scheme.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[32%] sm:w-full md:w-[31%] flex flex-row">
            <div className="w-[46.875%] sm:w-1/2 md:w-[45.16%] flex flex-col">
              <div className="w-full p-5 h-full">
                <div className="h-full text-[14px] leading-[2] sm:text-[12px] sm:text-center md:text-[9px] md:leading-4 font-Poppins flex flex-col justify-between">
                  {constants_Introlink.map((item, index) => (
                    <p key={index} className="w-full h-1/5">
                      <a
                        href={`/#${item.link}`}
                        onClick={(e) => handleScrollToSection(e, item.link)}
                        className="text-[#4D546C] hover:text-[#31C3A8] transition-all duration-200 ease-in-out"
                      >
                        {item.title}
                      </a>
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-[53.125%] sm:w-1/2 md:w-[54.83%]">
              <div className="w-full p-5 h-full">
                <div className="h-full text-[14px] leading-[2] sm:text-[12px] sm:text-center md:text-[9px] md:leading-4 font-Poppins flex flex-col">
                  {constants_Funclink.map((item, index) => (
                    <p key={index} className="w-full h-1/5">
                      <Link
                        to={item.link}
                        className="text-[#4D546C] hover:text-[#31C3A8] transition-all duration-200 ease-in-out"
                      >
                        {item.title}
                      </Link>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[18%] sm:w-full md:w-1/5 flex flex-col">
            <div className="px-5 pt-5 pb-0 sm:pt-0">
              <h4 className="sm:text-[14px] sm:text-center md:text-[10px] text-[16px] font-Overpass text-white font-semibold">
                Contact Information:
              </h4>
            </div>
            <div className="px-5 pb-5 pt-[13px] sm:pb-[13px]">
              <div className="w-full flex flex-row items-center sm:justify-center">
                <div className="mr-3 sm:mr-2 md:mr-2 lg:mr-[5px] w-6">
                  <span className="w-6 h-6 text-[24px]">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ color: "#00FFAC" }}
                    />
                  </span>
                </div>
                <div className="h-full text-[14px] leading-[2] sm:text-[9px] sm:text-center md:text-[9px] md:leading-4 font-Poppins">
                  <p>Paphos, Cyprus</p>
                </div>
              </div>
            </div>
            <div className="pl-2 py-4 sm:pt-0 sm:pb-5 w-full flex sm:justify-center">
              <div className="social-links">
                {constants_SocialLink.map((item, index) => (
                  <a key={index} href={item.link}>
                    <img
                      src={`./assets/img/${item.src}`}
                      alt={item.src}
                      className="sm:w-[14px] md:w-[10px]"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full ">
        <div className="overflow-hidden px-5 pt-0 pb-3 sm:px-0 lg:px-[100px] footer-line">
          <div className="max-w-[1174px] mx-auto w-full">
            <div className="px-5 pt-5 pb-3 sm:pb-[12px] sm:pt-5 md:pb-[12px] md:pt-5">
              <div className="text-[12px] sm:text-center md:text-[8px]">
                <p>
                  2022 - 2023 ADRESTUS CHAIN FOUNDATION © All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
