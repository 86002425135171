import DoubleLetters from "../../../component/MainBoard/doubleletters";
import HeadingLetters from "../../../component/MainBoard/headingletters";
import ContentLetters from "../../../component/MainBoard/contentletters";

const ViewPoint = () => {
  return (
    <div className="backgroundImage">
      <div className="flex flex-col pt-0 pb-[38px] px-24 md:px-10 sm:px-5 max-w-[1374px] m-auto">
        <div className="flex flex-row sm:flex-col py-5 gap-3">
          <div className="p-5 w-[49%] sm:w-full" data-aos="fade-zoom-in">
            <lottie-player
              src="./assets/lotties/first_lottie.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div
            className="flex flex-col sm:w-full w-[51%] sm:items-center sm:justify-center justify-center gap-5 md:gap-3 sm:gap-3"
            data-aos="fade-right"
          >
            <DoubleLetters text="VIEWPOINT" />
            <HeadingLetters text="What Is Adrestus?" />
            <ContentLetters text="Adrestus is an open sourced public blockchain platform, leveraging the security of the account model scheme. Adrestus is a mixed PoS and BFT protocol allowing specific blockchain settings to be modified by making use of smart payments. For instance, the block size of Adrestus can be increased without the need of a hard fork." />
          </div>
        </div>
        <div className="flex flex-row sm:flex-col py-5">
          <div
            className="flex flex-col sm:w-full w-[51%] sm:items-center sm:justify-center justify-center gap-5 md:gap-3 sm:gap-3"
            data-aos="fade-right"
          >
            <DoubleLetters text="VIEWPOINT" />
            <HeadingLetters text="Blockchain Ready for Business" />
            <ContentLetters text="Adrestus is Business-friendly smart payment platform that provides a standardized, stable and safe development for smartpayments, enabling business-friendly smart coding, deploying and executing. Adrestus is also a decentralized application platform, and is so new technology can always be added." />
          </div>
          <div className="p-5 w-[49%] sm:w-full" data-aos="fade-zoom-in">
            <lottie-player
              src="./assets/lotties/second_lottie.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              direction="1"
              mode="normal"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPoint;
