const BenefitItem = ({ src, text }) => (
  <div>
    <div className="w-full p-0 m-0">
      <div>
        <div className="m-5 relative text-center sm:mb-[-11px]">
          <div className="benefit-item inline-block max-w-full">
            <img src={src} alt={src} className="max-w-full relative z-[9]" />
          </div>
        </div>
      </div>
      <div>
        <div className="m-5 mt-[-15px] sm:mt-5">
          <div className="text-white font-Poppins font-normal text-center leading-[32px] text-[20px] lg:text-[16px] lg:leading-[26px] md:text-[12px] md:leading-[20px] sm:text-[20px] sm:leading-[32px] mb-4">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BenefitItem;
