import Intro from "./intro";
import Concept from "./concept";
import Benefit from "./benefit";
import Extra from "./extra";

const Staking = () => {
  return (
    <div className=" overflow-x-hidden">
      <Intro />
      <Concept />
      <Benefit />
      <Extra />
    </div>
  );
};

export default Staking;
