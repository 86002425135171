const FeatureItem = ({ src, title, content, borderColor }) => (
  <div className="sm:px-5 sm:pb-5 sm:pt-0 p-[11px]" data-aos="fade-up">
    <div
      className={`pt-[3px] pb-0 pr-0 pl-[2px] sm:pt-6 sm:px-1 sm:pb-2 md:p-0 w-full dot-border ${borderColor}`}
    >
      <div className="w-full px-5 pt-5 pb-3">
        <div className="leading-[0] text-left flex sm:justify-center">
          <div className="w-[54px] md:w-[27px] max-w-full">
            <img src={`./assets/img/${src}.png`} alt="src" />
          </div>
        </div>
      </div>
      <div className="w-full px-5 pt-0 pb-[9px] sm:pt-[14px]">
        <h5 className="sm:text-center text-[16px] sm:text-[18px] md:text-[10px] lg:text-[14px] text-white">
          <span className="block lg:text-[80%] font-Overpass font-semibold">
            {title}
          </span>
        </h5>
      </div>
      <div className="w-full px-5 pt-3 pb-5 sm:pb-[20px]">
        <div className="text-[12px] sm:text-[9px] sm:leading-4 md:text-[9px] font-Poppins">
          <p className="sm:text-[12px] sm:text-center sm:leading-6">
            {content}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureItem;
