import AdvantageItem from "../../../component/MainBoard/advantageItem";
import DivineLine from "../../../component/MainBoard/divineLine";
import MiddleDoubleLetters from "../../../component/MainBoard/middleDoubleLetters";
import MiddleHeadingLetters from "../../../component/MainBoard/middleHeadingLetters";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { constants_upside, constants_downside } from "./constants";

const Advantage = () => {
  const combinedConstants = [...constants_upside, ...constants_downside];
  return (
    <div className="flex flex-col py-0 pb-[20px] px-24 md:px-10 sm:px-5 max-w-[1374px] m-auto">
      <div className="pt-5 flex items-center justify-center" data-aos="zoom-in">
        <img
          src="./assets/img/Polygon.png"
          alt="Polygon"
          className="sm:w-[117px] md:w-[90px] w-[184px]"
        ></img>
      </div>
      <div data-aos="zoom-in">
        <div className="flex w-full sm:items-center sm:justify-center items-center justify-center gap-5 md:gap-3 sm:gap-3">
          <MiddleDoubleLetters text="WHY ADRESTUS" />
        </div>
      </div>
      <div className="flex items-center justify-center pb-5" data-aos="zoom-in">
        <MiddleHeadingLetters text="Why We Are?" />
      </div>
      <div className="flex flex-col sm:hidden">
        <div className="flex sm:flex-col flex-row items-between pt-5 sm:gap-7 gap-4">
          {constants_upside.map((item, index) => (
            <AdvantageItem
              key={index}
              src={item.src}
              headTitle={item.headTitle}
              contentText={item.contentText}
            />
          ))}
        </div>
        <div className="flex sm:pt-10 sm:flex-col flex-row items-between pt-5 sm:gap-7 gap-4">
          {constants_downside.map((item, index) => (
            <AdvantageItem
              key={index}
              src={item.src}
              headTitle={item.headTitle}
              contentText={item.contentText}
            />
          ))}
        </div>
      </div>
      <div className="hidden sm:flex max-w-[767px]">
        <Carousel
          showThumbs={false}
          axis="horizontal"
          className="mx-auto w-full advantage"
        >
          {combinedConstants.map((item, index) => (
            <AdvantageItem
              key={index}
              src={item.src}
              headTitle={item.headTitle}
              contentText={item.contentText}
            />
          ))}
        </Carousel>
      </div>
      <div className="w-full flex items-center justify-center py-12">
        <button className="sm:w-[172px] sm:h-[50px] md:w-[114px] md:h-[45px] w-[205px] h-[66px] rounded-[33px] colorBtn">
          <a
            href="https://github.com/Adrestus-net/Adrestus"
            className="flex items-center justify-center"
          >
            <span className="text-[#081113] text-[16px] md:text-[12px] sm:text-[12px] left-5 font-semibold text-centerm font-Poppins">
              Start Now
            </span>
          </a>
        </button>
      </div>
      <DivineLine />
    </div>
  );
};

export default Advantage;
