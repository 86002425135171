const MiniContentLetters = ({ text }) => (
  <div
    className="sm:mt-0 sm:text-[14px] leading-snug py-1"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-delay="0"
  >
    <p className="sm:text-[12px] sm:leading-6 md:text-[10px] md:leading-[18px] lg:leading-[26px] text-[16px] font-normal leading-6 max-w-[353px] w-full font-Poppins">
      {text}
    </p>
  </div>
);

export default MiniContentLetters;
