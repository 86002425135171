import DoubleLetters from "../../../component/MainBoard/doubleletters";
import DivineLine from "../../../component/MainBoard/divineLine";
import FeatureItem from "./featureItem";

import { constant_downside, constant_upside } from "./constants";

const PaymentFeature = () => {
  return (
    <div className="px-5 pb-[45px] sm:px-0 overflow-hidden flex flex-col">
      <div className="max-w-[1414px] w-full flex flex-row sm:flex-col mx-auto">
        <div className="sm:w-full w-1/2 flex flex-col justify-center">
          <div
            className="w-full m-5 sm:mt-0 sm:ml-[10px] md:ml-[10px] lg:ml-[-20px]"
            data-aos="fade-zoom-in"
          >
            <div className="max-w-full sm:w-[349px] md:w-[294px] w-[592px] sm:mx-auto">
              <img src="./assets/img/WhyWeExist.svg" alt="WhyWeExist"></img>
            </div>
          </div>
          <div
            className="w-full mx-5 mb-5 mt-[-50px] sm:mt-[-31px] sm:mx-0 md:mt-[-35px] md:mb-[35px]"
            data-aos="fade-zoom-in"
          >
            <DoubleLetters text="HOW ADRESTUS" />
          </div>
          <div
            className="w-full mx-5 mb-5 mt-[-20px] sm:mt-[-10px] sm:mx-0 md:mt-[-30px]"
            data-aos="fade-zoom-in"
          >
            <h2 className="sm:text-center sm:text-[22px] md:text-[22px] text-[46px]">
              <span className="lg:text-[80%] block text-white font-Overpass leading-[1.4] font-semibold">
                Smart payments Features
              </span>
            </h2>
          </div>
          <div className="w-full my-5 mr-5 ml-[-16px] sm:mt-[5px] sm:mb-[10px]">
            <div className="w-full max-w-full">
              <img
                src="./assets/img/SmartPaymentFeatures.png"
                alt="SPF"
                className=" mix-blend-lighten mx-auto"
              />
            </div>
          </div>
        </div>
        <div className="sm:w-full w-1/2 flex flex-row sm:flex-col">
          <div className="sm:w-full w-1/2 flex flex-col justify-center">
            {constant_upside.map((item, index) => (
              <FeatureItem
                key={index}
                src={item.src}
                title={item.title}
                content={item.content}
                borderColor={item.borderColor}
              ></FeatureItem>
            ))}
          </div>
          <div className="sm:w-full w-1/2 flex flex-col">
            {constant_downside.map((item, index) => (
              <FeatureItem
                key={index}
                src={item.src}
                title={item.title}
                content={item.content}
                borderColor={item.borderColor}
              ></FeatureItem>
            ))}
          </div>
        </div>
      </div>
      <span className="pt-[60px] w-full p-5">
        <DivineLine />
      </span>
    </div>
  );
};

export default PaymentFeature;
