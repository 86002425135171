import DoubleLetters from "../../../component/MainBoard/doubleletters";
import RotateEarth from "../../../component/Three/rotateEarth";

const PostConent = () => {
  return (
    <div className="pt-[145px] pb-[167px] px-5 sm:pt-[113px] sm:pb-[49px] sm:px-0 md:pl-10 md:pt-[104px] md:pb-[99px] md:pr-0 lg:pl-[100px] lg:pr-0 xl:px-[100px] intoBackground">
      <div className="max-w-[1174px] mx-auto">
        <div className="flex flex-row sm:flex-col py-10">
          <div
            className="w-[51%] justify-center sm:w-full flex flex-col items-center sm:justify-center text-left sm:text-center mx-auto p-0 m-0"
            data-aos="zoom-in"
          >
            <DoubleLetters text="PERSPECTIVE" />
            <div className="w-full ml-[-10px] mr-5 relative">
              <div className="w-[541px] relative inline-block sm:hidden">
                <img
                  src="./assets/img/Responsible.png"
                  alt="Responsible"
                  className="w-[350px] h-[70px]"
                ></img>
              </div>
            </div>
            <div className="w-full mt-[-85px] md:mt-[-65px] sm:mt-0 lg:p-0 overflow-breakword">
              <h2 className=" md:text-[50px] text-[60px] p-0 m-0 font-semibold">
                <span className=" md:text-[46px] xl:text-[60px] sm:text-[50px] letter-color z-[99] relative block font-Overpass">
                  Responsible
                </span>
              </h2>
            </div>
            <div className="w-full">
              <h2 className="sm:text-[36px] leading-snug font-semibold p-0 m-0 md:text-[28px] lg:text-[38px] text-[48px]">
                <span className="text-white capitalize font-Overpass">
                  Let’s take money into the 21st century
                </span>
              </h2>
            </div>
            <div className="w-full flex flex-row sm:flex-col gap-3 sm:pt-4 items-center">
              <span className="bg-[#39CCEC] w-[3px] h-[47px] sm:w-[54px] sm:h-[3px] rounded-md"></span>
              <div className="m-5 sm:mt-0 sm:text-[14px] leading-snug">
                <p className="sm:text-[15px] md:text-[12px] text-[16px] font-normal leading-6 font-Poppins">
                  The Internet's First Large-Scale Blockchain Has Been Launched!
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center sm:justify-center gap-3 pt-3">
              <button className="w-[229px] h-[66px] rounded-[33px] colorBtn md:w-[150px] md:h-[45px] sm:w-[150px] sm:h-[50px]">
                <a
                  href="https://vimeo.com/751790519"
                  className="flex items-center justify-center"
                >
                  <span className="text-[#081113] text-[16px] md:text-[12px] sm:text-[12px] left-5 font-semibold text-center font-Poppins">
                    Access Video
                  </span>
                </a>
              </button>
              <button className="w-[229px] h-[66px] rounded-[33px] colorBtn md:w-[150px] md:h-[45px] sm:w-[150px] sm:h-[50px]">
                <a
                  href="https://github.com/Adrestus-net/Adrestus"
                  className="flex items-center justify-center"
                >
                  <span className="text-[#081113] text-[16px] md:text-[12px] sm:text-[12px] left-5 font-semibold text-center font-Poppins">
                    Access Code
                  </span>
                </a>
              </button>
            </div>
          </div>
          <div className="w-[49%] sm:w-full overflow-hidden flex justify-center">
            <RotateEarth />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostConent;
