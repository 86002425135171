const HeadingLettersB = ({ text }) => (
  <div className="py-4">
    <h2 className="sm:text-center">
      <span className="capitalize sm:text-[30px] md:text-[27px] text-[46px] lg:text-[36px] leading-7 font-semibold text-white font-Overpass">
        {text}
      </span>
    </h2>
  </div>
);

export default HeadingLettersB;
