const MiddleHeadingLetters = ({ text }) => (
  <div
    className="py-3"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-delay="0"
  >
    <h2 className="sm:text-center">
      <span className="capitalize sm:text-[30px] md:text-[25px] text-[46px] lg:text-[36px] leading-7 font-semibold text-white font-Overpass">
        {text}
      </span>
    </h2>
  </div>
);

export default MiddleHeadingLetters;
