const constants = [
  {
    src: "./assets/img/Tokenomics_Staking.png",
    title: "Staking",
    content:
      "All validators are required to stake ADR with a higher stake equating in a high probability of being selected to fulfill data requests.",
  },
  {
    src: "./assets/img/Tokenomics_Governance.png",
    title: "Governance",
    content:
      "ADR token holders are able to vote for the protocol upgrades and parameter changes on Adrestus.",
  },
  {
    src: "./assets/img/Tokenomics_Utility.png",
    title: "Utility",
    content:
      "ADR tokens will be used to pay as a transaction, query fee, and also for data access behind paywall (private APIs)",
  },
];

export default constants;
