const constant_upside = [
  {
    src: "SafterSmartPayments.png",
    title: "Safter Smart Payments",
    content:
      "Adrestus makes designing safer smart payments effortless, whether you’re sending your first trasnaction or launching your fiftieth dApp. By automatically detecting bugs.",
  },
  {
    src: "ValidatorReward.png",
    title: "Validator Reward",
    content:
      "In Adrestus, the power is in the hands of the users holding stake. Every user receives an amount of rewards proportional to their stake for every block that is committed to the chain. We do so to encourage users to join the Adrestus platform and accelerate our path to decentralization.",
  },
  {
    src: "EnergyEfficient.png",
    title: "Energy Efficient At Scale",
    content:
      "As network demand increases, Adrestus’ energy use remains contant. its unique architecture makes it the only platform that can deliver increased energy efficiency as TPS (Transactions per Seconds) scales.",
  },
];
const constant_downside = [
  {
    src: "ProvenSecurity.png",
    title: "Proven Security",
    content:
      "Our founders experience at JPMorgan revealed the limitations of existing blockchain solutions. To sove for the security and throughput demands of financal services clents, Kadena runs on Proof of Work, which is rellable, secure, and battle-hardened.",
  },
  {
    src: "IndustrialScalability.png",
    title: "Industrial Scalability",
    content:
      "Our founders’ experience revealed the limitations of existing blockchain solutions. To solve for the security and throughput demands of financial services clients, Adrestus runs on Proof of Stake, which is reliable secure, and battle-hardened.",
  },
];

export { constant_downside, constant_upside };
