import AdvantageItem2 from "../../../component/MainBoard/advantageItem2";
import { Carousel } from "react-responsive-carousel";

import constants from "./constants";

const Tokenmoics = () => {
  return (
    <div
      id="tokenomics"
      className="w-full TKBackground pt-0 pb-[71px] sm:px-5 sm:pb-[60px] md:pb-10 md:px-5 lg:px-[100px]"
    >
      <div className="max-w-[1174px] flex flex-col items-between mx-auto">
        <div className="flex flex-col items-center py-5">
          <div className="w-[530px] sm:w-[254px] md:w-[254px] h-auto sm:mx-auto md:ml-[-1px] ml-[-40px]">
            <img src="../assets/img/Tokenomics.svg" alt="Tokenomics" />
          </div>
          <div className="z-10 mt-[-42px] md:mt-[-32px] sm:mt-[-28px] p-5">
            <h2 className="sm:text-center">
              <span className="capitalize  text-[46px] sm:text-[36px] md:text-[30px] lg:text-[36.8px] leading-[1.4] font-semibold text-white font-Overpass">
                Tokenomics
              </span>
            </h2>
          </div>
        </div>
        <div className="flex flex-row w-full sm:hidden">
          {constants.map((item, index) => (
            <AdvantageItem2
              key={index}
              src={item.src}
              headTitle={item.title}
              contentText={item.content}
            />
          ))}
        </div>
        <div className="sm:flex hidden w-full">
          <Carousel
            showThumbs={false}
            axis="horizontal"
            className="mx-auto w-full advantage2"
          >
            {constants.map((item, index) => (
              <AdvantageItem2
                key={index}
                src={item.src}
                headTitle={item.title}
                contentText={item.content}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Tokenmoics;
