const TableHeader = ({ src }) => (
  <th className="sm:text-[10px] sm:py-[9px] sm:px-0 md:text-[10px] md:px-0 md:py-[9px] pt-[17px] pb-[14px] px-5 lg:text-[12px] font-semibold xl:text-[16px] 2xl:text-[16px]">
    <span className=" text-center mini-table-header items-center justify-center gap-3 sm:gap-0 text-white w-full">
      <img
        src={`./assets/img/${src}.svg`}
        alt={src}
        className="sm:w-5 md:w-5"
      />
      <span>{src}</span>
    </span>
  </th>
);

export default TableHeader;
