import AdvantageItem2 from "../../../component/MainBoard/advantageItem2";
import { Carousel } from "react-responsive-carousel";
import constants from "./constants";

const Advantage2 = () => {
  return (
    <div className="w-full AdBackground sm:pt-[30px] sm:pb-[60px] sm:px-5 md:pb-[40px] lg:px-[100px] pt-0 pb-[71px]">
      <div className=" max-w-[1174px] flex flex-row items-between m-auto">
        <div className="w-full AdBackground sm:pt-0 sm:pb-0 sm:px-0 md:pb-[40px] lg:px-[100px] pt-0 pb-[71px]">
          <div className="max-w-[1174px] ">
            <div className="flex flex-row items-between m-auto sm:hidden">
              {constants.map((item, index) => (
                <AdvantageItem2
                  key={index}
                  src={item.src}
                  headTitle={item.title}
                  contentText={item.content}
                />
              ))}
            </div>
            <div className="hidden sm:flex">
              <Carousel
                showThumbs={false}
                axis="horizontal"
                className="mx-auto w-full advantage2"
              >
                {constants.map((item, index) => (
                  <AdvantageItem2
                    key={index}
                    src={item.src}
                    headTitle={item.title}
                    contentText={item.content}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantage2;
