const constants_top = [
  {
    title: "White Paper Release",
    content: "scalable block processing",
    suffix: true,
  },
  {
    title: "BLS Signatures",
    content: "Batched Signature Validation",
    suffix: true,
  },
  {
    title: "Faster Block Propagation",
    content: "Efficiently Verify Blocks",
    suffix: true,
  },
  {
    title: "Ensure Liveness",
    content:
      "Make Sure Validators Always Comfort With The Protocol And Achieve Consensus ",
    suffix: true,
  },
  {
    title: "Adaptive Cross-Zone Transactions",
    content:
      "Ensure System Process Parallel Cross-Zone Transactions With Improve Security  ",
    suffix: true,
  },
  {
    title: "Revised Whitepaper Release",
    content: "Improve Idea Conception, And Research Of WhitePaper  ",
  },
  {
    title: "Social Media Marketing",
    content:
      "Improve Existing Social Media Infrastructure With Bots, Moderators, And More",
  },
  { title: "Wallet v1.0 Release", content: "Desktop Wallet Release" },
  {
    title: "Staking/Delegation",
    content: "Launch Staking/Delegation And Election Program",
  },
  { title: "Testnet Release", content: "Release The Testnet Phase" },
];
const constants_bottom = [
  {
    title: "Canonical Transaction Ordering",
    content: "Scalable Block Processsing",
    suffix: true,
  },
  {
    title: "VRF/VDF Addition",
    content:
      "Improve Security Of Zones By Including Unpredictable/Unbiasable Randomness ",
    suffix: true,
  },
  {
    title: "Adrestus PBFT",
    content:
      "Add Improved PBFT With Fewer  Steps And Retaining The Same Security Level",
    suffix: true,
  },
  {
    title: "Make Source Code Publi",
    content: "Adrestus Source Code Release",
    suffix: true,
  },
  {
    title: "Improvement UI/UX",
    content: "Continuous improvement on the UI/UX Design",
    suffix: true,
  },
  {
    title: "Create Partnership",
    content: "Establish Partnerships With Additional Protocols",
  },
  { title: "Safety Module", content: "Improvement On Safety Module" },
  { title: "Mobile Wallet Release", content: "Android And Ios Wallet Release" },
  {
    title: "Revise Core Idea",
    content: "Revise Of Initial Blockchain Implementation",
  },
  {
    title: "Partnership With Cryptocurrency Exchanges",
    content: "Incorporate More Markets",
  },
];

export { constants_bottom, constants_top };
