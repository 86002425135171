const RoadmapItem = ({ title, content, suffix }) => (
  <div className={`roadmap-box ${suffix ? "roadmap-box-width" : ""}`}>
    <div className="roadmap-content-box">
      <h5 className="font-Poppins text-[20px] mb-0 font-semibold leading-[1.4] tracking-[0px] capitalize lg:text-[18px] md:text-[14px] sm:text-[14px] text-white">
        {title}
      </h5>
      <p className="mb-0 font-Poppins sm:text-[12px] md:text-[10px] text-[14px]">
        {content}
      </p>
    </div>
    <img
      src="./assets/img/RoadmapItem.svg"
      alt="RoadmapItem"
      className="max-w-full min-w-[100px] md:w-[55px] md:min-w-[55px] sm:w-[55px] sm:min-w-[55px]"
    />
  </div>
);

export default RoadmapItem;
