import ContentLetters from "../../../component/MainBoard/contentletters";
import DivineLine from "../../../component/MainBoard/divineLine";
import HeadingLettersB from "../../../component/MainBoard/headingLettersB";
import MiddleDoubleLetters from "../../../component/MainBoard/middleDoubleLetters";
import TableHeader from "../../../component/MainBoard/tableHeader";
import tableData from "./constants.js";

const CompareChain = () => {
  return (
    <div className="w-full sm:pt-10 sm:pb-5 md:py-[60px] sm:px-5 pt-[99px] pb-[85px] lg:px-[100px]">
      <div className="max-w-[1209px] flex flex-col p-0 items-center m-auto">
        <div
          className="sm:w-[320px] md:w-[322px] w-[546px]"
          data-aos="fade-zoom-in"
        >
          <img
            src="../assets/img/Blockchains.svg
          "
            alt="blockchain"
          />
        </div>
        <div
          className="z-10 mt-[-42px] md:mt-[-32px] sm:mt-[-28px] p-5"
          data-aos="fade-zoom-in"
        >
          <MiddleDoubleLetters text="BLOCKCHAIN" />
        </div>
        <div data-aos="fade-zoom-in">
          <HeadingLettersB text="Comparing To Other Blockchains" />
        </div>
        <div className="text-center" data-aos="fade-zoom-in">
          <ContentLetters text="Random reshuttling of nodes into other shards. The consensus uses an unbiasable randomness source generated by the block propose." />
        </div>
        <div className="p-5 w-full pt-10" data-aos="fade-zoom-in">
          <table className="chainTable p-0">
            <thead className=" bg-transparent border-none">
              <tr>
                <th className="sm:w-[160px] md:min-w-[173px] md:w-[173px] sm:text-[10px] sm:px-9 sm:py-0 md:text-[10px] lg:text-[12px] ">
                  <span></span>
                </th>
                <TableHeader src="Bitcoin" />
                <TableHeader src="Ethereum" />
                <TableHeader src="Polkadot" />
                <TableHeader src="Adrestus" />
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr className="text-center" key={index}>
                  <td>
                    <div>{row.name}</div>
                  </td>
                  <td>{row.bitcoin}</td>
                  <td>{row.ethereum}</td>
                  <td>{row.polkadot}</td>
                  <td>{row.adrestus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <span className="pt-[60px] w-full p-5">
          <DivineLine />
        </span>
      </div>
    </div>
  );
};

export default CompareChain;
