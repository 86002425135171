const constants_upside = [
  {
    src: "./assets/img/Advantage1.svg",
    headTitle: "Easy Blockchain Innovation",
    contentText:
      "Create a custom blockchain in minutes using our API. Connect your chain to Adrestus and get interoperability and security from day one. This ease of development helps Adrestus’ network grow.",
  },
  {
    src: "./assets/img/Advantage2.svg",
    headTitle: "Economic & Transactional",
    contentText:
      "Adrestus provides unprecedented economic scalability by enabling a common set of validators to secure multiple blockchains. Adrestus provides transactional scalability by spreading transactions across multiple parallel blockchains termed zones.",
  },
  {
    src: "./assets/img/Advantage3.svg",
    headTitle: "User-Driven Governance",
    contentText:
      "Adrestus has a sophisticated governance system where all stakeholders have a voice. Network upgrades are coordinated on-chain and enacted autonomously and without forking the network, ensuring that Adrestus’ development remains future-proof and community-driven.",
  },
];
const constants_downside = [
  {
    src: "./assets/img/Advantage4.svg",
    headTitle: "High Energy Efficiency",
    contentText:
      "Adrestus consumes a small fraction of the energy used by conventional blockchains thanks to its next-generation nominated proof-of-stake (NPoS) model.",
  },
  {
    src: "./assets/img/Advantage5.svg",
    headTitle: "User-Driven Governance",
    contentText:
      "Adrestus’ novel data availability and validity scheme allows zones to interact with each other in a meaningful way. Zones remain independent in their governance, but united in their security.",
  },
  {
    src: "./assets/img/Advantage6.svg",
    headTitle: "True Interoperability",
    contentText:
      "Adrestus enables cross-blockchain transfers of any type of data. Connecting to Adrestus gives you the ability to interoperate with a wide variety of blockchains in the Adrestus ’ network",
  },
];

export { constants_downside, constants_upside };
