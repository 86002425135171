import { useEffect } from "react";
import * as THREE from "three";

const params = {
  backgroundColor: 0x000000,
  rotationSpeed: 0.5,
  spotLight: {
    intensity: 10,
    color: 0x0ea46b,
  },

  globeMaterial: {
    opacity: 0.5,
    color: 0x10c6b1,
  },

  globeModel: {
    rotationX: 10,
    rotationZ: 0,
  },
};
let container;
let camera, scene, renderer;
let globeMesh;
let spotLight;
var nflag = false;
const RotateEarth = () => {
  const init = () => {
    container = document.getElementById("sphere1");
    renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setClearColor(params.backgroundColor, 0);
    renderer.setSize(
      document.getElementById("sphere1").clientWidth,
      document.getElementById("sphere1").clientHeight
    );
    camera = new THREE.PerspectiveCamera(
      60,
      document.getElementById("sphere1").clientWidth /
        document.getElementById("sphere1").clientHeight,
      0.1,
      1000
    );
    camera.position.z = 500;

    scene = new THREE.Scene();
    // earth
    var loader = new THREE.TextureLoader();
    const texture = loader.load(
      "https://raw.githubusercontent.com/chibilo/files/main/8081_earthlights10k.jpg"
    );
    const geometry = new THREE.SphereGeometry(200, 20, 20);
    var material = new THREE.MeshLambertMaterial({
      side: THREE.DoubleSide,
      opacity: params.globeMaterial.opacity,
      transparent: true,
      depthWrite: false,
      color: params.globeMaterial.color,
      map: texture,
    });

    globeMesh = new THREE.Mesh(geometry, material);
    globeMesh.rotation.x = (Math.PI / 180) * params.globeModel.rotationX;
    globeMesh.rotation.z = (Math.PI / 180) * params.globeModel.rotationZ;
    scene.add(globeMesh);

    // lights
    const ambient = new THREE.AmbientLight(0xffffff);
    scene.add(ambient);

    spotLight = new THREE.SpotLight(params.spotLight.color);
    spotLight.position.set(100, 100, 300);
    spotLight.intensity = params.spotLight.intensity;
    spotLight.angle = Math.PI / 4;
    scene.add(spotLight);

    container.appendChild(renderer.domElement);
  };
  const render = () => {
    requestAnimationFrame(render);

    globeMesh.rotation.y -= (params.rotationSpeed * Math.PI) / 180;
    renderer.render(scene, camera);
  };
  useEffect(() => {
    if (!nflag) {
      init();
      render();
      nflag = true;
    }
  }, []);
  return (
    <div
      id="sphere1"
      data-node="ec5jiq3tw8zs"
      className="h-[580px] w-full flex justify-center items-center text-center sm:w-full md:ml-[100px]"
    ></div>
  );
};

export default RotateEarth;
